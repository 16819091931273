.App {
  text-align: center;
  background-color: #282c34;
  max-height: 100vh;

}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.gall .image-gallery-content {
  height:100%
}

.image-gallery-slide {
  background-color: inherit;  
}

.originalImage img{
  height: auto;

  background-color: #282c34;
  width:auto;
  max-height: 600px;

  max-width: 100vw;
}

body {
  background: #282c34;
}
.image-gallery-bullets {
  position: static;
}
.image-gallery-bullets .image-gallery-bullets-container  {
  margin: 10px;
}
@import "~react-image-gallery/styles/css/image-gallery.css";

