.season {
    display: inline-block;
    color: white;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
    -webkit-animation: fade 2s;
    animation: fade 2s;
    opacity: 1;
}

@-webkit-keyframes fade {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.selectedalbum {
    cursor: default;
}

.selectedalbum:before {
    width: 50%;
    left: 25%;
    height: 1px;
    content: "";
    bottom: 0;
    position: absolute;
    border-bottom: 1px solid white;
    -webkit-animation: createLine 1s;
    animation: createLine 1s;
}

.unselectedalbum:before {
    height: 1px;
    content: "";
    bottom: 0;
    position: absolute;
    border-bottom: 1px solid white;
    -webkit-animation: removeLine 1s;
    animation: removeLine 1s;
}

@-webkit-keyframes createLine {
    from {
        width: 0%;
        left: 50%;
    }
    to {
        width: 50%;
        left: 25%;
    }
}

@keyframes createLine {
    from {
        width: 0%;
        left: 50%;
    }
    to {
        width: 50%;
        left: 25%;
    }
}

@-webkit-keyframes removeLine {
    from {
        width: 50%;
        left: 25%;
    }
    to {
        width: 0%;
        left: 50%;
    }
}

@keyframes removeLine {
    from {
        width: 50%;
        left: 25%;
    }
    to {
        width: 0%;
        left: 50%;
    }
}